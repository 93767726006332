.background-container {
  background-image: url("/avl.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.support-footer {
  bottom: 0px;
  padding: 1em;
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .support-footer {
    position: absolute;
  }
}

@media only screen and (max-width: 768px) {
  .section {
    padding: 1rem 1rem;
  }
}
